<template>
  <v-layout id="Login" class="view" v-resize="onResize">
    <v-container
      fluid
      fill-height
      class="d-flex flex-col background-gradient ma-0 pa-0"
    >
      <v-row no-gutters>
        <v-col class="d-flex justify-center">
          <transition name="fade-img">
            <img
              class="home-image"
              :src="`/img/${getLogos.home}`"
              v-on:load="onLoaded"
              v-show="loaded"
              alt="logomarca"
            />
          </transition>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>

      <v-row no-gutters>
        <v-col class="d-flex justify-center">
          <transition name="fade-dialog" v-if="!register">
            <LoginDialog
              v-show="loaded"
              @RegisterSelected="register = true"
              :windowSize="windowSize"
            >
            </LoginDialog>
          </transition>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>

      <v-row no-gutters>
        <v-col class="d-flex justify-center align-center">
          <span class="white--text mr-1">Powered by</span>
          <span>
            <a href="https://znap.com.br/" class="znap-link" target="_blank"
              >ZNAP Technologies</a
            >
          </span>
          <span class="ml-1 text-caption white--text">®</span>
          <span class="ml-1 text-caption white--text">
            v. {{ appVersion }}
          </span>
        </v-col>
      </v-row>

      <!-- <transition name="fade-dialog" v-else>
        <RegisterDialog v-show="loaded" @LoginSelected="register = false">
        </RegisterDialog>
      </transition> -->

      <ReloadDialog />
    </v-container>
  </v-layout>
</template>

<script>
import { version } from "../../package";
import { mapGetters } from "vuex";
import LoginDialog from "@/components/LoginDialog";
import ReloadDialog from "@/components/ReloadDialog";
// import RegisterDialog from '@/components/RegisterDialog'

export default {
  name: "Login",

  components: {
    LoginDialog,
    ReloadDialog,
    // RegisterDialog
  },

  computed: {
    ...mapGetters("theme", ["getLogos"]),
  },

  data() {
    return {
      loaded: false,
      register: false,
      windowSize: { x: 0, y: 0 },
      appVersion: version,
    };
  },

  methods: {
    onLoaded: function () {
      this.loaded = true;
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>

<style scoped>
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-img-enter-active {
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-dialog-enter-active {
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.aparecer {
  display: block;
}

h1 {
  font-size: 50pt;
  text-shadow: 1px 5px 9px grey;
  font-weight: 100;
}

img {
  width: 300px;
  height: auto;
}

#Login {
  padding: 0px !important;
  height: 100%;
  width: 100%;
}

.home-image {
  max-width: 12rem;
}

.footer-container {
  /* position: absolute; */
  bottom: 12px;
  /* display: flex; */
  justify-content: center;
  width: 99vw;
}

.znap-link {
  color: white;
  cursor: pointer;
  transition: all 200ms;
  text-decoration: none;
}

.znap-link:hover {
  color: #494f3f;
  font-weight: bold;
}
</style>